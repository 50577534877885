import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reloadTransfers: false,
  totalTransferencias: 0,
};

const transfers = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    setReloadTransfers(state, action) {
      state.reloadTransfers = action.payload;
    },
    setTotalTransferencias(state, action) {
      state.totalTransferencias = action.payload;
    },
  },
});

export default transfers.reducer;
export const { setReloadTransfers, setTotalTransferencias } = transfers.actions;
