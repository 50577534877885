import { createTheme } from "@mui/material/styles";

const Palette = (mode) => {
  return createTheme({
    palette: {
      primary: {
        light: "#145401",
        main: "#145401",
        dark: "#145401",
        contrastText: "#ffffff",
      },
    },
  });
};

export default Palette;
