import { useDispatch } from "react-redux";
import { DialogTitle, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import BotaoPrincipal from "./BotaoPrincipal";
import BotaoSecundario from "./BotaoSecundario";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogSimulationType({
  infoDialogSimulationType,
  setInfoDialogSimulationType,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setInfoDialogSimulationType({ open: false }));
  };

  const handleConfirmar = (type) => {
    infoDialogSimulationType.confirm(type);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={infoDialogSimulationType.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 2,
              top: 2,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Deseja realizar uma simulação de que tipo?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <BotaoPrincipal
            onClick={() => {
              handleConfirmar("b2b");
            }}
            autoFocus>
            SIMULAÇÃO B2B
          </BotaoPrincipal>
          <BotaoSecundario
            onClick={() => {
              handleConfirmar("b2c");
            }}
            autoFocus>
            SIMULAÇÃO B2C
          </BotaoSecundario>
        </DialogActions>
      </Dialog>
    </div>
  );
}
