import axios from "axios";
import {
  retornaUrlServerMoviPay,
  trataErro,
  retornaHeaders,
} from "@/services/api";

export function post(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }

    axios
      .post(retornaUrlServerMoviPay() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function put(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }

    axios
      .put(retornaUrlServerMoviPay() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}
export function get(endpoint, params) {
  var promise = new Promise((resolve, reject) => {
    const config = retornaHeaders();
    config.params = params;
    axios
      .get(retornaUrlServerMoviPay() + endpoint, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function remove(endpoint) {
  var promise = new Promise((resolve, reject) => {
    axios
      .delete(retornaUrlServerMoviPay() + endpoint, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}
