import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BotaoText from "./BotaoText";

export default function DialogConfirmacao({
  infoDialogConfirmacao,
  setInfoDialogConfirmacao,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setInfoDialogConfirmacao({ open: false }));
  };

  const handleNao = () => {
    if (infoDialogConfirmacao.acaoNao) {
      infoDialogConfirmacao.acaoNao();
    }
    handleClose();
  };
  const handleSim = () => {
    if (infoDialogConfirmacao.acaoSim) {
      infoDialogConfirmacao.acaoSim();
    }
    handleClose();
  };

  return (
    <Dialog
      open={infoDialogConfirmacao.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {infoDialogConfirmacao.titulo || "Aviso"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {infoDialogConfirmacao.descricao}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BotaoText onClick={handleNao}>Não</BotaoText>
        <BotaoText onClick={handleSim} autoFocus>
          Sim
        </BotaoText>
      </DialogActions>
    </Dialog>
  );
}
