import { useRoutes } from "react-router-dom";

// project import
import AreaAbertaRoutes from "./AreaAbertaRoutes";
import DashboardRoutes from "./DashboardRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([DashboardRoutes, AreaAbertaRoutes]);
}
