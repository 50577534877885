// material-ui
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { get } from "@/services/api";
import {
  setReloadTransfers,
  setTotalTransferencias,
} from "@/store/reducers/transfers";
import NavGroup from "./NavGroup";
import menuItem from "@/menu-items";

const Navigation = () => {
  const { reloadTransfers } = useSelector((state) => state.transfers);
  const dispatch = useDispatch();

  const carregaTransferencias = useCallback(async () => {
    const result = await get("secure/transfer/count-pending");
    if (result) {
      dispatch(setReloadTransfers(false));
      dispatch(setTotalTransferencias(result.count));
    }
  }, [dispatch]);

  useEffect(() => {
    carregaTransferencias();
  }, [reloadTransfers, carregaTransferencias]);

  useEffect(() => {
    const interval = setInterval(() => {
      carregaTransferencias();
    }, 60000);
    return () => clearInterval(interval);
  }, [carregaTransferencias]);

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
