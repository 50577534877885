import PropTypes from "prop-types";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";

const DrawerHeader = ({ open }) => {
  return (
    <DrawerHeaderStyled style={{ backgroundColor: "#2e312e" }} open={open} />
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
