// project import
import Routes from "./routes";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
const App = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc5yWgjAAAAANZXN6dF7fci68cey63-dlIf6kcd">
      <ThemeCustomization>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </GoogleReCaptchaProvider>
  );
};

export default App;
