import axios from "axios";

export async function perdaAutorizacao() {
  alert("Por favor, faça login novamente!");
  window.location.href = "/";
}

export function retornaUsuario(login) {
  let usuarioLogadoStorage = {};
  if (!login && !usuarioLogadoStorage) {
    perdaAutorizacao();
    return;
  }
  return usuarioLogadoStorage;
}

export function retornaUsuarioLogado() {
  let usuarioLogado = {};
  try {
    const dadosUsuario = localStorage.getItem("dadosUsuarioLogado");
    if (!dadosUsuario || dadosUsuario === "null") {
      usuarioLogado = {};
    } else {
      usuarioLogado = JSON.parse(dadosUsuario);
    }
  } catch (e) {}
  return usuarioLogado;
}
export function retornaAuthorization() {
  let usuarioLogado = retornaUsuarioLogado();
  return "Bearer " + (usuarioLogado.userToken || "");
}

export function retornaHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: retornaAuthorization(),
    },
  };
}

export function retornaUrlServerQuitaBoletos() {
  return retornaUrlServerBase().quitaboletos;
}

export function retornaUrlServerMoviPay() {
  return retornaUrlServerBase().movipay;
}

export function retornaUrlServerEstatisticas() {
  return retornaUrlServerBase().estatisticas;
}

export function retornaUrlServerBase() {
  if (ehHomologacao()) {
    return {
      quitaboletos:
        "https://api-admin.homologacao.quitapay.com/internal-admin/",
      movipay: "https://api.homologacao.movipay.com.br/internal-admin/",
      estatisticas: "",
    };
  }
  if (ehProducao()) {
    return {
      quitaboletos: "https://api-admin.quitapay.com/internal-admin/",
      movipay: "https://api.movipay.com.br/internal-admin/",
      estatisticas: "https://api-estatisticas.quitaboletos.com/",
    };
  }
  return {
    quitaboletos: "http://localhost:21080/internal-admin/",
    movipay: "http://localhost:21095/internal-admin/",
    estatisticas: "http://localhost:21093/",
  };
}

export function ehHomologacao() {
  return process.env.REACT_APP_ENVIRONMENT === "HOMOLOG";
}

export function ehProducao() {
  return process.env.REACT_APP_ENVIRONMENT === "PROD";
}

export function post(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }

    axios
      .post(retornaUrlServerQuitaBoletos() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}
export async function getTokenReport() {
  const result = await get(`secure/report/token`);
  return result.token;
}
export function put(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }

    axios
      .put(retornaUrlServerQuitaBoletos() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export const trataErro = (error, reject) => {
  if (error.response && error.response.status === 401) {
    perdaAutorizacao();
    return;
  }
  reject(error);
};
export function get(endpoint, params) {
  var promise = new Promise((resolve, reject) => {
    const config = retornaHeaders();
    config.params = params;
    axios
      .get(retornaUrlServerQuitaBoletos() + endpoint, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function remove(endpoint) {
  var promise = new Promise((resolve, reject) => {
    axios
      .delete(retornaUrlServerQuitaBoletos() + endpoint, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}
