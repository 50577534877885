import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "@/store";
import { setReloadWarnings } from "@/store/reducers/warnings";
import { get } from "@/services/api";
import { useNavigate } from "react-router-dom";
import { retornaUrlHelp } from "@/services/utils";
import { Container } from "@mui/system";
import {
  Badge,
  Box,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import LogoBranco from "@/components/LogoBranco";
import Profile from "./Profile";
import MobileSection from "./MobileSection";
import DialogRelease from "@/layout/Release/DialogRelease";
import UpdateIcon from "@mui/icons-material/Update";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BotaoText from "@/components/BotaoText";
import MailIcon from "@mui/icons-material/Mail";
import "./header.scss";

const HeaderContent = () => {
  const { reloadWarnings } = useSelector((state) => state.warnings);
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [warnings, setWarnings] = useState([]);
  const [abrirUpdates, setAbrirUpdates] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const addDots = (title) => {
    return title.length > 70 ? title.substr(0, 70) + "..." : title;
  };
  const carregaWarnings = async () => {
    const result = await get("secure/alert/user/resume");
    if (result) {
      setWarnings(result);
      dispatch(setReloadWarnings(false));
    }
  };

  useEffect(() => {
    carregaWarnings();
  }, [reloadWarnings]);
  useEffect(() => {
    const interval = setInterval(() => {
      carregaWarnings();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleClickOpen = () => {
    setAbrirUpdates(true);
  };

  const handleCloseUpdates = () => {
    setAbrirUpdates(false);
  };

  return (
    <>
      {!matchesXs && (
        <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
          <a href="/area-admin">
            <LogoBranco style={{ height: "35px", paddingLeft: "1rem" }} />
          </a>
        </Box>
      )}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      {warnings.count > 0 && !matchesXs && (
        <>
          <Typography className="notification-arrow" sx={{ mr: 2 }}>
            →
          </Typography>
        </>
      )}
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge sx={{ mr: 2 }} badgeContent={warnings.count} color="success">
          <MailIcon sx={{ color: "white" }} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Grid container spacing={0} sx={{ p: 2 }}>
          <Grid item size={12}>
            {warnings.count > 0 ? (
              <Container>
                <Typography
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      `/area-admin/avisos-usuario/detalhes/${warnings.alert.idAlert}`,
                      "_blank"
                    );
                  }}>
                  {addDots(warnings.alert.title)}
                </Typography>
              </Container>
            ) : (
              <Typography>Nenhum novo aviso.</Typography>
            )}
          </Grid>
          <Grid item size={12} align="center">
            <BotaoText
              onClick={() => {
                handleClose();
                navigation("avisos-usuario");
              }}>
              Ver Todos
            </BotaoText>
          </Grid>
        </Grid>
      </Popover>
      <IconButton
        onClick={() => {
          window.open(retornaUrlHelp(), "_blank");
        }}
        title="Central de Ajuda">
        <HelpOutlineIcon sx={{ color: "white" }} />
      </IconButton>
      <IconButton onClick={handleClickOpen} title="Notas de atualizações">
        <UpdateIcon sx={{ color: "white" }} />
      </IconButton>
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
      {abrirUpdates && (
        <DialogRelease {...{ abrirUpdates, handleCloseUpdates }} />
      )}
    </>
  );
};

export default HeaderContent;
