import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";

// third-party
import { Provider as ReduxProvider } from "react-redux";
import * as ReactDOMClient from "react-dom/client";
// project import
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import "./global.scss";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

let persistor = persistStore(store);
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  </StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
