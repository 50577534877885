import VpnKeyIcon from "@mui/icons-material/VpnKey";
const dashboard = {
  id: "group-dashboard-Geral",
  title: "Conta",
  url: "/area-admin",
  type: "group",
  children: [
    {
      id: "alterar-senha",
      title: "ALTERAR SENHA",
      type: "item",
      url: "alterar-senha",
      icon: VpnKeyIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
