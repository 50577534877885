import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  status: [],
};
const dashboard = createSlice({
  name: "status",
  initialState,
  reducers: {
    setStatus(state, action) {
      state.status = action.payload;
    },

    resetStatus(state) {
      state.status = [];
    },
  },
});

export default dashboard.reducer;
export const { setStatus, resetStatus } = dashboard.actions;
