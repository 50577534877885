import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import BotaoText from "./BotaoText";
import BotaoPrincipal from "./BotaoPrincipal";
import Grid from "@mui/material/Grid2";

export default function DialogFormatoRelatorio({
  infoDialogFormatoRelatorio,
  setInfoDialogFormatoRelatorio,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setInfoDialogFormatoRelatorio({ open: false }));
  };

  const handleCancelar = () => {
    handleClose();
  };
  const handleConfirmar = (tipo) => {
    infoDialogFormatoRelatorio.confirmar(tipo);
    handleClose();
  };

  return (
    <Dialog
      open={infoDialogFormatoRelatorio.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"Deseja gerar o relatório em qual formato?"}
        </DialogContentText>

        <Grid sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
          <BotaoPrincipal
            onClick={() => {
              handleConfirmar("xls");
            }}
            autoFocus>
            XLS
          </BotaoPrincipal>
          <BotaoPrincipal
            onClick={() => {
              handleConfirmar("pdf");
            }}
            autoFocus>
            PDF
          </BotaoPrincipal>
        </Grid>
      </DialogContent>
      <DialogActions>
        <BotaoText onClick={handleCancelar}>Cancelar</BotaoText>
      </DialogActions>
    </Dialog>
  );
}
