import {
  retornaUrlServerQuitaBoletos,
  retornaUrlServerEstatisticas,
  ehHomologacao,
  getTokenReport,
  ehProducao,
} from "@/services/api";
import Moment from "moment";
import { get } from "./api";

export function formatMonetario(valor) {
  valor = valor || 0;

  if (typeof valor == "string") {
    valor = parseFloat(valor);
  }

  return valor.toLocaleString("pt-br", {
    signDisplay: "auto",
    style: "currency",
    currency: "BRL",
  });
}

export async function sleep(msec) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}

export function apenasNumeros(str) {
  return (str || "").toString().replace(/\D/g, "");
}
export function apenasMonetario(str) {
  return (str || "").toString().replace(/[^01234567890,.]/g, "");
}

export function retornaUrlDashboard(route) {
  return `/area-admin/${route}`;
}

export function aoPressionarEnter(event, executar) {
  if (event.key === "Enter") {
    executar();
  }
}
export async function abrirRelatorio(urlRelatorio, enviarEmail) {
  let url = !enviarEmail ? retornaUrlServerQuitaBoletos() : "";
  url += `${urlRelatorio}`;
  const tokenReport = await getTokenReport();
  if (url.indexOf("?") === -1) {
    url += "?tokenReport=";
  } else {
    url += "&tokenReport=";
  }
  url += tokenReport;

  if (enviarEmail) {
    get(urlRelatorio);
  } else {
    window.open(url, "_blank");
  }
}
export async function abrirRelatorioEstatistincas(urlRelatorio) {
  let url = retornaUrlServerEstatisticas() + `${urlRelatorio}`;
  const tokenReport = await getTokenReport();
  if (url.indexOf("?") === -1) {
    url += "?tokenReport=";
  } else {
    url += "&tokenReport=";
  }
  url += tokenReport;
  window.open(url, "_blank");
}

export const retornaDescricaoChargeType = (chargeType) => {
  if (chargeType === "clienteInforma") {
    return "Cliente informa os dados do boleto/PIX";
  }
  return "Parceiro informa os dados do boleto/PIX";
};

export function retornaUrlApp() {
  if (ehHomologacao()) {
    return "https://app.homologacao.quitaboletos.com/";
  }
  if (!ehProducao()) {
    return "http://localhost:3001/";
  }
  return "https://app.quitaboletos.com.br/";
}
export function retornaUrlAppMoviPay() {
  if (ehHomologacao()) {
    return "https://homologacao.movipay.com.br/";
  }
  if (!ehProducao()) {
    return "http://localhost:3007/";
  }
  return "https://app.movipay.com.br/";
}

export function retornaUrlHelp() {
  if (ehProducao() || ehHomologacao()) {
    return "https://ajuda.quitaboletos.com.br";
  }

  return "http://localhost:3000";
}

export function retornaFormaPagamento(payment_method) {
  if (payment_method === "pix") {
    return "PIX";
  }
  if (payment_method === "boleto") {
    return "Boleto";
  }
  return "Cartão de crédito";
}

export function estaDentroDoPeriodoDeDias(data_inicial, data_final, qtd_dias) {
  const diferencaDias = Moment(data_final).diff(data_inicial, "days");

  return diferencaDias <= qtd_dias;
}

export const formataCpfCnpj = (valor) => {
  if (!valor) {
    return 0;
  }
  valor = valor.replace(/\D/g, ""); // remove todos os caracteres não numéricos

  if (valor.length <= 11) {
    valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else if (valor.length <= 14) {
    valor = valor.replace(/(\d{2})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
    valor = valor.replace(/(\d{3})(\d)/, "$1/$2");
    valor = valor.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  }

  return valor;
};

export const validaCpfCnpj = (valor) => {
  valor = valor.replace(/\D/g, ""); // remove todos os caracteres não numéricos
  if (valor.length === 11) {
    let soma = 0;
    let resto;

    if (
      valor === "00000000000" ||
      valor === "11111111111" ||
      valor === "22222222222" ||
      valor === "33333333333" ||
      valor === "44444444444" ||
      valor === "55555555555" ||
      valor === "66666666666" ||
      valor === "77777777777" ||
      valor === "88888888888" ||
      valor === "99999999999"
    ) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(valor.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(valor.substring(9, 10))) {
      return false;
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma += parseInt(valor.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(valor.substring(10, 11))) {
      return false;
    }

    return true;
  } else if (valor.length === 14) {
    let tamanho = valor.length - 2;
    let numeros = valor.substring(0, tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11;

    return resultado;
  }
};

export const formatarTelefone = (valor) => {
  if (!valor) return "";

  const numero = valor.replace(/[^\d]/g, "");

  if (numero.length === 11) {
    return `(${numero.substring(0, 2)}) ${numero.substring(
      2,
      7
    )}-${numero.substring(7)}`;
  } else if (numero.length === 10) {
    return `(${numero.substring(0, 2)}) ${numero.substring(
      2,
      6
    )}-${numero.substring(6)}`;
  } else {
    return valor;
  }
};

export function checkOrigin(origin) {
  switch (origin) {
    case "CADASTRO-INDICACAO":
      return "Cadastro feito por indicação";
    case "CADASTRO-ADMIN":
      return "Cadastro feito pela área admin";
    case "CADASTRO-AREA-ABERTA":
      return "Cadastro feito pelo site";
    default:
      return `Cadastro ${origin}`;
  }
}

export function formataData(data, formato) {
  return Moment(data).format(formato || "DD/MM/YYYY");
}

export const formataTipoChave = (chave) => {
  let chaveFormatada = "";

  switch (chave) {
    case "CpfCnpj":
      chaveFormatada = "CPF/CNPJ";
      break;
    case "Email":
      chaveFormatada = "E-mail";
      break;

    default:
      chaveFormatada = chave;
      break;
  }

  return chaveFormatada;
};
export const formataChave = (chave, tipo) => {
  let chaveFormatada = "";

  switch (tipo) {
    case "CpfCnpj":
      chaveFormatada = formataCpfCnpj(chave);
      break;
    case "Telefone":
      chaveFormatada = formatarTelefone(chave);
      break;

    default:
      chaveFormatada = chave;
      break;
  }

  return chaveFormatada;
};

export const extensaoValida = (extensao) => {
  const extensoesPermitidas = ["jpg", "jpeg", "png", "pdf"];

  if (extensoesPermitidas.includes(extensao)) {
    return true;
  } else {
    return false;
  }
};

export const ocultarDocumento = (valor) => {
  if (!valor) {
    return "";
  }

  const documento = formataCpfCnpj(valor);

  if (valor.length === 11) {
    const asterisco = "*";
    const numeros = documento.substring(3, 12);

    return asterisco.repeat(3) + numeros + asterisco.repeat(2);
  } else if (valor.length === 14) {
    const asterisco = "*";
    const numeros = documento.substring(2, 16);

    return asterisco.repeat(2) + numeros + asterisco.repeat(2);
  }
};

export async function retornaDataUrlArquivo(arquivo) {
  const myReader = new FileReader();
  const promise = new Promise((resolve, reject) => {
    myReader.onloadend = (e) => {
      let novoArquivo = {};
      novoArquivo.id = arquivo.name + new Date().getTime();
      novoArquivo.nome = arquivo.name;
      novoArquivo.extensao = arquivo.name.split(".").pop();

      novoArquivo.arquivo = myReader.result.toString().split(";base64,").pop();
      resolve(novoArquivo);
    };
    myReader.readAsDataURL(arquivo);
  });

  return promise;
}
