import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ehHomologacao } from "../../services/api";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import BotaoText from "../BotaoText";
import "./styles.scss";

const ResponsiveAppBar = (params: any) => {
  const navigation = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar className="header" position="static">
      <Container className="header" maxWidth="xl">
        <Toolbar className="header" disableGutters>
          <Typography
            noWrap
            sx={{
              color: "#FFF",
              mr: 2,
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
            }}>
            ÁREA DO ADMINISTRADOR
            {ehHomologacao() ? " - HOMOLOGAÇÃO" : ""}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{ color: "white" }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              <MenuItem
                onClick={() => {
                  window.location.href = "https://quitaboletos.com.br";
                }}>
                <Typography textAlign="center">VOLTAR AO SITE</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}>
            <img src="/logo_2.png" className="logo-header" alt=""></img>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
            }}>
            <BotaoText
              className="header-item"
              onClick={() => {
                window.location.href = "https://quitaboletos.com.br/";
              }}
              sx={{ my: 2, mr: 2, display: "block" }}>
              Voltar ao site
            </BotaoText>
            {params.exibirEntrar && (
              <BotaoText
                className="header-item header-item-entrar"
                onClick={() => {
                  navigation("/login");
                }}
                sx={{ my: 2, color: "white", display: "block" }}>
                Entrar
              </BotaoText>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
