import { IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BotaoPrincipal from "./BotaoPrincipal";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogContrato({
  infoDialogContrato,
  setInfoDialogContrato,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setInfoDialogContrato({ open: false }));
  };
  const chooseTypeSend = (optionSend) => {
    if (infoDialogContrato.acaoOk) {
      infoDialogContrato.acaoOk(optionSend);
      handleClose();
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={infoDialogContrato.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        ATENÇÃO
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item size={12} align="center">
              <Typography>Confirma que deseja enviar o contrato?</Typography>
              <Typography>Qualquer envio anterior será inválidado.</Typography>
            </Grid>
            <Grid item size={12} align="center">
              <BotaoPrincipal
                sx={{ mr: 2 }}
                onClick={() => chooseTypeSend("email")}>
                E-MAIL
              </BotaoPrincipal>
              <BotaoPrincipal
                sx={{ mr: 2 }}
                onClick={() => chooseTypeSend("sms")}>
                SMS
              </BotaoPrincipal>
              <BotaoPrincipal
                sx={{ mr: 2 }}
                onClick={() => chooseTypeSend("whatsapp")}>
                WHATSAPP
              </BotaoPrincipal>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
