// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import dashboard from "./dashboard";
import user from "./user";
import geral from "./geral";
import warnings from "./warnings";
import transfers from "./transfers";
import status from "./status";
import meta from "./meta";
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  user,
  geral,
  dashboard,
  warnings,
  transfers,
  status,
  meta, 
});

export default reducers;
