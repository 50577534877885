// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  mesReferencia: "",
};

const meta = createSlice({
  name: "meta",
  initialState,
  reducers: {
    setMesReferencia(state, action) {
      state.mesReferencia = action.payload;
    },
    resetMes(state) {
      state.mesReferencia = initialState.mesReferencia;
    },
  },
});

export default meta.reducer;

export const { setMesReferencia } = meta.actions;
