import { useState, useEffect, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { get } from "@/services/api";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  Slide,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import BotaoText from "@/components/BotaoText";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogRelease({ abrirUpdates, handleCloseUpdates }) {
  const dispatch = useDispatch();

  const [releases, setReleases] = useState([]);
  const [carregar, setCarregar] = useState(false);
  useEffect(() => {
    const carregaReleases = async () => {
      try {
        dispatch(activeSpinner());

        const result = await get("secure/system-update");
        dispatch(cancelSpinner());

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }

        setReleases(result);
      } catch (e) {
        console.error(e);
        dispatch(cancelSpinner());
        dispatch(
          activeSpinner({
            open: true,
            descricao:
              "Houve um erro ao buscar os dados. Por favor, recarregue a página.",
          })
        );
      }
    };

    if (carregar) {
      setCarregar(false);
      carregaReleases();
    }
  }, [carregar, dispatch]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  // deixa por default o último item expandido
  const expandeAccordion = (_release) => {
    for (const index of releases) {
      if (index.date === _release.date) {
        return true;
      }
      return false;
    }
  };

  return (
    <Dialog
      open={abrirUpdates}
      onClose={handleCloseUpdates}
      scroll="body"
      TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography
          color="primary"
          sx={{
            textAlign: "center",
            fontSize: 25,
            fontWeight: "bold",
          }}>
          Notas de Versão
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseUpdates}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {releases.length > 0 && (
          <>
            {releases.map((release) => {
              return (
                <Accordion
                  defaultExpanded={expandeAccordion(release)}
                  sx={{ mb: 1 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ background: "#bcbcbc2d" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {moment(release.date).format("DD/MM/YYYY")}
                    </Typography>
                  </AccordionSummary>

                  <Typography
                    color="primary"
                    sx={{
                      mt: 2,
                      ml: 2,
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}>
                    Quita Boletos
                  </Typography>
                  {release.updates.map((item) => {
                    return (
                      <AccordionDetails sx={{ py: 1 }}>
                        <Typography
                          sx={{
                            fontSize: 14,
                          }}>
                          {`- ${item.description}`}
                        </Typography>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <BotaoText onClick={handleCloseUpdates}>FECHAR</BotaoText>
      </DialogActions>
    </Dialog>
  );
}
